import React, { useState } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import { Button } from '@mui/material';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ConsentForm from './consent-form/ConsentForm';
import PrivacyNotice from './privacy-notice/PrivacyNotice';
import { getDateForForms } from '../../history.service';

const LegalForms = () => {
  const [openConsentDialog, setOpenConsentDialog] = useState(false);
  const [openPrivacyNoticeDialog, setOpenPrivacyNoticeDialog] = useState(false);
  const [isPrivacyNoticeAccepted, setIsPrivacyNoticeAccepted] = useState(false);
  const [isConsentAccepted, setIsConsentAccepted] = useState(false);

  const handlePrivacyNoticeOpenClick = () => {
    setOpenPrivacyNoticeDialog(true);
  };

  const confirmPrivacyNoticeAccept = () => {
    setIsPrivacyNoticeAccepted(true);
    setOpenPrivacyNoticeDialog(false);
  };

  const cancelPrivacyNoticeAccept = () => {
    setOpenPrivacyNoticeDialog(false);
  };

  const handleConsentOpenClick = () => {
    setOpenConsentDialog(true);
  };

  const confirmConsentAccept = () => {
    setIsConsentAccepted(true);
    setOpenConsentDialog(false);
  };

  const cancelConsentAccept = () => {
    setOpenConsentDialog(false);
  };

  return (
    <div className="forms-container margin-top--md">
      <Accordion style={{ marginTop: '30px' }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div style={{ width: '100%' }}>
            <h4 className="margin-top--xxxs" style={{ fontWeight: '600' }}>
              Forms
            </h4>

            {(isConsentAccepted === false || isPrivacyNoticeAccepted === false) && (
              <p className="form-completion-status">
                <ErrorIcon color="error" style={{ marginBottom: '-6px' }} /> &nbsp; Forms have not
                been completed
              </p>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <p style={{ fontWeight: '500' }}>Requested by Detalks</p>
          <div className="flex-container justify-between margin-top--md align-start privacy-notice">
            <div>
              <p>Privacy notice (Detalks)</p>
              <p className="margin-top--sm gray-text">
                {isPrivacyNoticeAccepted
                  ? `Accepted on ${getDateForForms(Date.now())}`
                  : 'View and agree to our standard forms'}
              </p>
            </div>
            <Button
              size="small"
              variant="outlined"
              onClick={() => {
                handlePrivacyNoticeOpenClick();
              }}>
              View
            </Button>
          </div>
          <div className="flex-container justify-between margin-top--md align-start">
            <div>
              <p>Consent forms (Detalks)</p>
              <p className="margin-top--sm gray-text">
                {isConsentAccepted
                  ? `Accepted on ${getDateForForms(Date.now())}`
                  : 'View and agree to our standard forms'}
              </p>
            </div>
            <Button
              size="small"
              variant="outlined"
              onClick={() => {
                handleConsentOpenClick();
              }}>
              View
            </Button>
          </div>
          <PrivacyNotice
            confirmPrivacyNoticeAccept={confirmPrivacyNoticeAccept}
            isPrivacyNoticeAccepted={isPrivacyNoticeAccepted}
            cancelPrivacyNoticeAccept={cancelPrivacyNoticeAccept}
            openPrivacyNoticeDialog={openPrivacyNoticeDialog}
          />
          <ConsentForm
            openConsentDialog={openConsentDialog}
            cancelConsentAccept={cancelConsentAccept}
            isConsentAccepted={isConsentAccepted}
            confirmConsentAccept={confirmConsentAccept}
          />
        </AccordionDetails>
      </Accordion>

      <style jsx>
        {`
          .align-start {
            align-items: flex-start;
          }
          .form-completion-status {
            background-color: #fff4f1;
            padding: 10px 5px;
            border-radius: 5px;
          }
          .privacy-notice {
            border-bottom: 1px solid var(--border-gray);
            padding-bottom: 20px;
          }
        `}
      </style>
    </div>
  );
};

export default LegalForms;
