import React, { useEffect, useState } from 'react';
// import { timeSlots } from '../../../../helper/data.helper';
import { getTherapistAvailability } from '../../../../helper/api.helper';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';

const TimeslotSelect = ({ selectedTimeIndex, handleTimeClick, timeSlots }) => {
  return (
    <>
      {timeSlots && timeSlots.length > 0 && (
        <div className="margin-top--md">
          <ul
            className="flex-container list-items"
            style={{
              listStyleType: 'none',
              padding: 0,
              marginTop: '0px'
            }}>
            {timeSlots &&
              timeSlots.length > 0 &&
              timeSlots?.map((slot, index) => (
                <li
                  style={{ marginLeft: '10px' }}
                  key={slot.startTime}
                  className={index === selectedTimeIndex ? 'selected' : ''}
                  onClick={() => handleTimeClick(slot, index)}>
                  {slot.startTime} - {slot.endTime}
                </li>
              ))}
          </ul>
        </div>
      )}

      <style jsx>
        {`
          div {
            color: black;
            max-width: 350px;
            overflow: scroll;
            max-height: 300px;
            // border: 1px solid var(--gray-light);
            padding: 10px 0px;
          }
          li {
            text-align: center;
            padding: 5px 5px;
            cursor: pointer;
            border-radius: 3px;
            border: 1px solid #097eff;
            color: #097eff;
            margin-bottom: 10px;
            font-weight: 600;
            font-size: 12px;
            width: 120px;
          }
          li:hover {
            outline: white;
            color: white;
            background-color: #097eff;
          }
          .selected {
            background-color: #097eff;
            color: white;
          }
          .list-items {
            flex-wrap: wrap;
          }
        `}
      </style>
    </>
  );
};

export default TimeslotSelect;
