import React from 'react';
import LanguageIcon from '@mui/icons-material/Language';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import ChairOutlinedIcon from '@mui/icons-material/ChairOutlined';

const Details = ({ therapist }) => {
  const getCombinedText = (array, maxLength = 64) => {
    if (!array || array.length === 0) return '';
    let combinedText = '';
    array.some((item) => {
      const text = item?.text || '';
      if (!text) return false;
      if (combinedText.length + text.length + (combinedText ? 2 : 0) > maxLength) {
        const remainingSpace = maxLength - combinedText.length;
        combinedText += (combinedText ? ', ' : '') + text.slice(0, remainingSpace);
        return true;
      }
      combinedText += (combinedText ? ', ' : '') + text;
      return false;
    });
    if (combinedText.length >= 60) {
      combinedText = combinedText.slice(0, maxLength) + '...';
    }
    return combinedText;
  };
  return (
    <>
      {window.innerWidth > 500 && (
        <>
          <div className="flex-container name-div">
            <p
              style={{
                fontSize: 'larger',
                fontWeight: '700',
                color: 'black',
                minWidth: '120px'
              }}>
              {therapist.name
                ? therapist.name.length > 20
                  ? therapist.name.slice(0, 20) + '...'
                  : therapist.name
                : null}
            </p>
            <p>
              {therapist.meetingTypes.includes(0) && (
                <VideocamOutlinedIcon
                  fontSize="small"
                  color="primary"
                  sx={{
                    backgroundColor: 'rgb(216 230 253)',
                    padding: 0.2,
                    marginBottom: '-5px',
                    borderRadius: '10%'
                  }}
                />
              )}{' '}
              &nbsp;
              {therapist.meetingTypes.includes(1) && (
                <ChairOutlinedIcon
                  fontSize="small"
                  color="primary"
                  sx={{
                    backgroundColor: 'rgb(216 230 253)',
                    padding: 0.2,
                    marginBottom: '-5px',
                    borderRadius: '10%'
                  }}
                />
              )}
            </p>
            <p>
              {therapist.location
                ? therapist.location.length > 20
                  ? therapist.location.slice(0, 20) + '...'
                  : therapist.location
                : null}
            </p>
          </div>
          <span
            style={{
              borderBottom: '1px solid lightgrey',
              paddingBottom: '7px',
              color: 'black'
            }}>
            {therapist.role
              ? therapist.role.length > 25
                ? therapist.role.slice(0, 25) + '...'
                : therapist.role
              : null}
          </span>
        </>
      )}
      <div className="margin-top--md">
        {therapist?.specialities && therapist?.specialities?.length > 0 && (
          <div className="flex-container margin-top--sm">
            <StarBorderIcon fontSize="small" color="primary" />
            <p style={{ margin: '-1px 0px 0px 5px' }}>
              {window.innerWidth > 450 && 'Specialties: '}
              {getCombinedText(therapist?.specialities)}
            </p>
          </div>
        )}

        {therapist?.worksWith && therapist?.worksWith?.length > 0 && (
          <div className="flex-container margin-top--sm">
            <PeopleAltOutlinedIcon fontSize="small" color="primary" />
            <p style={{ margin: '-1px 0px 0px 5px' }}>
              {window.innerWidth > 450 && 'Works with: '}
              {getCombinedText(therapist?.worksWith)}
            </p>
          </div>
        )}

        {therapist?.languages && therapist?.languages?.length > 0 && (
          <div className="flex-container margin-top--sm">
            <LanguageIcon fontSize="small" color="primary" />
            <p style={{ margin: '-1px 0px 0px 5px' }}>
              {window.innerWidth > 450 && 'Languages: '}
              {getCombinedText(therapist?.languages)}
            </p>
          </div>
        )}

        {/* <p className="margin-top--sm">Next Available: 26th June, 7:30 pm</p> */}
      </div>
      <style jsx>
        {`
          p {
            color: black;
          }
          .name-div {
            justify-content: space-between;
            margin-bottom: 5px;
          }
          @media only screen and (max-width: 500px) {
            span,
            p {
              font-size: 13px;
            }
            .name-div {
              justify-content: space-between;
              // margin-left: 50px;
              width: 200px;
            }
          }
        `}
      </style>
    </>
  );
};

export default Details;
