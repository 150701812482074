import { createStore, action } from 'easy-peasy';

const store = createStore({
  user: false,
  logo: null,
  banner: null,
  qualified: false,
  emailDomains: null,
  clientData: null,
  setUser: action((state, payload) => {
    state.user = payload;
  }),
  setLogo: action((state, payload) => {
    state.logo = payload;
  }),
  setBanner: action((state, payload) => {
    state.banner = payload;
  }),
  setQualified: action((state, payload) => {
    state.qualified = payload;
  }),
  setEmailDomains: action((state, payload) => {
    state.emailDomains = payload;
  }),
  setClientData: action((state, payload) => {
    state.clientData = payload;
  })
});

export default store;
