import React, { useEffect, useState } from 'react';
import ChooseTherapist from './ChooseTherapist';
import SelfAssessment from './SelfAssessment';
import Courses from './courses/Courses';
import Blogs from './Blogs';
import { getOrgDetails } from '../../../helper/api.helper';
import toast from 'react-hot-toast';
import { useStoreState } from 'easy-peasy';
import { Helmet } from 'react-helmet';
import InstituteDetails from './institute-details/InstituteDetails';
import { getOrgCode } from '../../../helper/utils.helper';

const Home = () => {
  const [data, setData] = useState();
  const user = useStoreState((state) => state.user);
  const orgCode = getOrgCode();

  useEffect(() => {
    getOrgDetails(orgCode)
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 'success') {
          setData(res.data);
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }, [user]);
  return (
    <div
      style={{
        padding: window.innerWidth > 600 ? 'var(--space-lg)' : 'var(--space-md)'
      }}
      className="wrapper">
      <Helmet>
        <title>Home - DeTalks</title>
      </Helmet>

      <InstituteDetails />
      {orgCode === 'demo' ? <ChooseTherapist therapists={data?.counsellors} /> : null}
      <ChooseTherapist therapists={data?.counsellors} />
      {data?.selfAssessments && data?.selfAssessments.length ? (
        <SelfAssessment assessments={data?.selfAssessments} />
      ) : null}
      {data?.courses && data?.courses.length ? <Courses courses={data?.courses} /> : null}
      {orgCode === 'demo' ? <Blogs /> : null}
      <style jsx>
        {`
          .wrapper {
            min-height: 90vh;
            max-width: 1150px;
            margin: auto;
          }
        `}
      </style>
    </div>
  );
};

export default Home;
