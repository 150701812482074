import React from 'react';

const DetailItem = ({ icon: Icon, label, value }) => {
  return (
    <>
      <div
        className={`flex-container ${
          label === 'Experience: ' ||
          (label === 'License: ' && value?.[0]?.license !== '') ||
          (label !== 'License: ' && label !== 'Experience: ' && value && value.length > 0)
            ? 'margin-top--sm'
            : ''
        }`}>
        {label === 'Experience: ' && <Icon fontSize="small" color="primary" />}
        {label !== 'License: ' && label !== 'Experience: ' && value && value.length > 0 && (
          <Icon fontSize="small" color="primary" />
        )}
        {label === 'License: ' && value[0].license !== '' && (
          <Icon fontSize="small" color="primary" />
        )}
        <p style={{ margin: '-1px 0px 0px 8px' }}>
          {window.innerWidth > 450 && label === 'Experience: ' && label}
          {window.innerWidth > 450 &&
            label !== 'License: ' &&
            label !== 'Experience: ' &&
            value &&
            value.length > 0 &&
            label}
          {window.innerWidth > 450 && label === 'License: ' && value[0].license !== '' && label}

          {label === 'License: '
            ? value?.map((valueItem, i) => (
                <span key={i}>
                  {valueItem.license}
                  {i !== value.length - 1 && ', '}
                </span>
              ))
            : label === 'Qualification: '
              ? value?.map((valueItem, i) => (
                  <span key={i}>
                    {valueItem}
                    {i !== value.length - 1 && ', '}
                  </span>
                ))
              : label === 'Specialities: '
                ? value?.map((valueItem, i) => (
                    <span key={i}>
                      {valueItem.text}
                      {i !== value.length - 1 && ', '}
                    </span>
                  ))
                : label === 'Works with: '
                  ? value?.map((valueItem, i) => (
                      <span key={i}>
                        {valueItem.text}
                        {i !== value.length - 1 && ', '}
                      </span>
                    ))
                  : label === 'Languages: '
                    ? value?.map((valueItem, i) => (
                        <span key={i}>
                          {valueItem.text}
                          {i !== value.length - 1 && ', '}
                        </span>
                      ))
                    : label === 'Modalities: '
                      ? value?.map((valueItem, i) => (
                          <span key={i}>
                            {valueItem.text}
                            {i !== value.length - 1 && ', '}
                          </span>
                        ))
                      : label === 'Experience: '
                        ? value + (value === 0 ? ' year' : ' years +')
                        : null}
        </p>
      </div>
    </>
  );
};

export default DetailItem;
