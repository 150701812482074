import React from 'react';
import { getTime } from '../../../messages.service';

const Person = ({ person, activeChat, handleActiveChat }) => {
  return (
    <>
      <div
        key={person.id}
        className="flex-container justify-between chat-item-container"
        style={{
          backgroundColor: activeChat === person.id ? '#f1f1f1' : null,
          boxShadow: activeChat === person.id ? '0 1px 1px rgba(0, 0, 0, 0.1)' : null
        }}
        onClick={() => {
          handleActiveChat(person);
        }}>
        <div className="icon-div flex-container align-center justify-center">
          <p style={{ marginTop: '-1px', textAlign: 'center' }}>{person.name.charAt(0)}</p>
        </div>
        <div className="name-div">
          <p style={{ color: 'black' }}>{person.name}</p>
          <p style={{ marginTop: '-2px', fontSize: '12px' }}>
            {person?.messages?.[person.messages.length - 1]?.text.length > 30
              ? `${person.messages[person.messages.length - 1].text.substring(0, 30)}...`
              : person.messages[person.messages.length - 1]?.text}
          </p>
        </div>
        <div className="time-div">
          <p style={{ fontSize: '10px' }}>{getTime(person.updateTime)}</p>

          <div className="flex-container margin-top--xxxs" style={{ justifyContent: 'flex-end' }}>
            {person?.messages?.filter((message) => message.status === 'received' && !message.read)
              .length > 0 ? (
              <div className="unread-text-count flex-container align-center justify-center">
                {
                  person.messages.filter(
                    (message) => message.status === 'received' && !message.read
                  ).length
                }
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <style jsx>
        {`
          .chat-item-container {
            border-bottom: 1px solid var(--border-gray);
            padding: 10px 10px;
            cursor: default;
            // border-radius: 5px;
          }
          .chat-item-container:hover {
            background-color: #f1f1f1;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          }
          .name-div {
            flex-grow: 1;
            padding: 0px 5px;
          }
          .icon-div {
            width: 38px;
            height: 38px;
            background-color: #007aff;
            color: white;
            border-radius: 50%;
            font-size: 20px;
          }
          .unread-text-count {
            width: 15px;
            height: 15px;
            background-color: #007aff;
            color: white;
            border-radius: 50%;
            font-size: 10px;
          }
        `}
      </style>
    </>
  );
};

export default Person;
