import { ThemeProvider, createTheme } from '@mui/material/styles';
import ForgotPassword from './forgot-password/ForgotPassword';
import { Route, Routes } from 'react-router-dom';
import LoginMethods from './login-methods/LoginMethods';
import TopNav from '../../components/top-nav/TopNav';
import Footer from '../../components/Footer';

const Login = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#0884ff'
      },
      secondary: {
        main: '#73768c'
      }
    },
    typography: {
      button: {
        textTransform: 'capitalize'
      }
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <TopNav />
      <Routes>
        <Route index element={<LoginMethods />} />
        <Route path="/forgot-password/*" element={<ForgotPassword />} />
      </Routes>
      <Footer />
    </ThemeProvider>
  );
};

export default Login;
