import React from 'react';
import { Route, Routes } from 'react-router-dom';
import HistoryHeadings from './HistoryHeadings';
import ClientOrgProfile from './client-org-profile/ClientOrgProfile';

const History = () => {
  return (
    <div>
      <Routes>
        {/* <Route index element={<Navigate to="" replace />} /> */}
        <Route path="/" element={<HistoryHeadings />} />
        <Route path="/:id/*" element={<ClientOrgProfile />} />
      </Routes>
    </div>
  );
};

export default History;
