import React from 'react';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';

const ConsultationModes = () => {
  return (
    <>
      <span
        style={{
          backgroundColor: 'rgb(216 230 253)',
          padding: '3px 6px',
          borderRadius: '5px'
        }}>
        <VideocamOutlinedIcon fontSize="small" color="primary" sx={{ marginBottom: '-3px' }} />
        &nbsp; Online
      </span>

      <p className="margin-top--xs">Telephonic, Video Call</p>

      <style jsx>
        {`
          p,
          .main-div > span {
            color: black;
          }
        `}
      </style>
    </>
  );
};

export default ConsultationModes;
