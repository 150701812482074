export const getTime = (timestamp) => {
  const date = new Date(timestamp);

  const optionsTime = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  };

  const formattedTime = date.toLocaleString(undefined, optionsTime);

  return formattedTime;
};

export const getMessageDate = (timestamp) => {
  const date = new Date(timestamp);

  const optionsDate = {
    weekday: 'short',
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  };

  const formattedDay = date.toLocaleString(undefined, optionsDate);

  return formattedDay;
};
