import { Box, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React from 'react';
// import { locations } from '../../../../helper/data.helper';

const LocationSelect = ({ selectedLocation, handleLocationChange, therapist, locations }) => {
  return (
    <>
      <h4>How would you like to meet ?</h4>
      <RadioGroup
        value={selectedLocation}
        onChange={handleLocationChange}
        sx={{ marginBottom: '32px', marginTop: '10px' }}>
        {locations?.map((location, index) => (
          <Box
            key={location.id}
            sx={{
              display: 'flex',
              alignItems: 'center',
              border: `1px solid ${selectedLocation === location + index ? '#097eff' : '#ccc'}`,
              borderRadius: '4px',
              padding: '8px 8px 8px 15px',
              marginBottom: '8px',
              maxWidth: '325px'
            }}>
            <FormControlLabel
              key={index}
              value={location.id}
              control={<Radio color="primary" />}
              label={location.meetingType === 0 ? 'Online' : `In-Person: ${location.location}`}
            />
          </Box>
        ))}
      </RadioGroup>
      <style jsx>
        {`
          h4 {
            color: black;
          }
        `}
      </style>
    </>
  );
};

export default LocationSelect;
