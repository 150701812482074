import React from 'react';
import { eventTypes } from '../../../../helper/data.helper';
import { MenuItem, Select } from '@mui/material';

const SessionSelect = ({ selectedEventType, handleEventTypeChange, services }) => {
  return (
    <>
      <h4>Choose your session type.</h4>
      <Select
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 300
            }
          }
        }}
        value={selectedEventType}
        onChange={handleEventTypeChange}
        displayEmpty
        fullWidth
        // size="small"
        sx={{
          marginTop: '6px',
          marginBottom: '8px',
          maxWidth: `350px`
        }}>
        {services?.map((service, index) => {
          return (
            <MenuItem key={service.id} value={service.id} sx={{ minWidth: '340px' }}>
              <span style={{ marginRight: '8px' }}>{service.title}</span>-&nbsp;
              <span
                style={{
                  backgroundColor: 'rgb(216 230 253)',
                  padding: '3px 6px',
                  borderRadius: '5px'
                }}>
                {service.duration} min
              </span>
            </MenuItem>
          );
        })}
      </Select>
      <style jsx>
        {`
          h4 {
            color: black;
          }
        `}
      </style>
    </>
  );
};

export default SessionSelect;
