import React, { useEffect, useRef, useState } from 'react';
import MessageItem from './message-item/MessageItem';
import { getMessageDate } from '../../messages.service';
import { peopleList } from '../../../../../helper/data.helper';
import { IconButton, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SendIcon from '@mui/icons-material/Send';

const ChatBox = ({ id }) => {
  const [messages, setMessages] = useState(null);
  const [person, setPerson] = useState(null);

  const messagesEndRef = useRef(null);
  let lastDate = '';
  let showDate = '';
  let messageDate = '';
  const getDate = (timestamp) => {
    messageDate = getMessageDate(timestamp);
    showDate = messageDate !== lastDate;
    return (lastDate = messageDate);
  };

  useEffect(() => {
    const person = peopleList.find((person) => person.id == id);
    if (person) {
      setMessages(person.messages);
      setPerson(person);
    }
  }, [id]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'auto' });
  }, [messages]);

  return (
    <>
      {person && window.innerWidth < 720 && (
        <div className="client-details flex-container">
          <div className="icon-div flex-container align-center justify-center">
            <p style={{ marginTop: '-1px', textAlign: 'center' }}>{person?.name?.charAt(0)}</p>
          </div>
          <p style={{ marginLeft: '10px' }}>{person?.name}</p>
        </div>
      )}

      <div className="chatbox">
        {messages?.map((message, index) => {
          getDate(message.time);
          return (
            <MessageItem
              key={index}
              message={message}
              showDate={showDate}
              messageDate={messageDate}
              getDate={getDate}
            />
          );
        })}
        <div ref={messagesEndRef} />
      </div>
      <div className="input-div flex-container align-center">
        <IconButton size="small">
          <AddIcon color="primary" />
        </IconButton>
        <TextField
          multiline
          type="text"
          size="small"
          fullWidth
          style={{ backgroundColor: 'white', maxWidth: '90%' }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                border: '1px solid black'
              }
            }
          }}
        />
        <IconButton size="small">
          <SendIcon color="primary" />
        </IconButton>
      </div>
      <style jsx>
        {`
          .client-details {
            position: sticky;
            top: -1px;
            background-color: #f2f2f2;
            width: 100%;
            min-height: 60px;
            align-items: center;
          }
          .icon-div {
            width: 38px;
            min-width: 38px;
            min-height: 38px;
            height: 38px;
            background-color: #007aff;
            color: white;
            border-radius: 50%;
            font-size: 20px;
            margin-left: 10px;
          }
          .chatbox {
            padding: 10px 0px;
            flex-grow: 1;
            overflow-y: auto;
          }
          .input-div {
            width: 100%;
            padding: 5px 0px;
            position: sticky;
            bottom: 0px;
            background-color: #f2f2f2;
          }
        `}
      </style>
    </>
  );
};

export default ChatBox;
