import React from 'react';
import LocationItem from './LocationItem';
import ChairOutlinedIcon from '@mui/icons-material/ChairOutlined';

const AddressDetails = ({ locations }) => {
  return (
    <>
      <p style={{ paddingTop: '30px' }}>
        <span
          style={{
            backgroundColor: 'rgb(216 230 253)',
            padding: '3px 6px',
            borderRadius: '5px'
          }}>
          <ChairOutlinedIcon fontSize="small" color="primary" sx={{ marginBottom: '-3px' }} />
          &nbsp; In-person
        </span>
      </p>
      {locations?.map(
        (location, i) =>
          location.meetingType === 1 && (
            <div key={i} className="flex-container map-div margin-top--md">
              <LocationItem location={location} />
            </div>
          )
      )}
      <style jsx>
        {`
          @media only screen and (max-width: 431px) {
            .map-div {
              flex-direction: column;
            }
          }
        `}
      </style>
    </>
  );
};

export default AddressDetails;
