import React from 'react';
import { Helmet } from 'react-helmet';
import { Route, Routes } from 'react-router-dom';
import Inbox from './inbox/Inbox';

const Messages = () => {
  return (
    <>
      <Helmet>
        <title>Messages - DeTalks</title>
      </Helmet>
      <div>
        <Routes>
          <Route path="/" element={<Inbox />} />
          <Route path="/:id" element={<Inbox />} />
        </Routes>
      </div>
      <style jsx>{`
        :global(.active) {
          color: var(--primary-color);
        }
      `}</style>
    </>
  );
};

export default Messages;
