import React from 'react';
import { IconButton } from '@mui/material';
//import {MenuItem} from '@mui/material/';
//import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import cobrandinglogo from "../../assets/cobrandingLogo.png";
import ThaparLogo from '../../assets/ThaparLogo.png';
import styles from './logocontainer.module.scss';
import { Menu, ArrowBack } from '@mui/icons-material';
import { Link, useMatch, useNavigate } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';

export default function Logo({ handleSidebar }) {
  const dashboard = useMatch('/dashboard/*');
  const logo = useStoreState((state) => state.logo);

  const clientOrgProfile = useMatch('/dashboard/history/:id');
  const messages = useMatch('/dashboard/messages/:id');

  const navigate = useNavigate();

  return (
    <div className={styles.logoContainer}>
      {dashboard ? (
        clientOrgProfile ? (
          <div className={styles.actionButton}>
            <IconButton
              color="inherit"
              aria-label="back"
              edge="start"
              onClick={() => {
                if (clientOrgProfile) navigate('/dashboard/history');
              }}>
              <ArrowBack />
            </IconButton>
          </div>
        ) : messages ? (
          <div className={styles.actionButton}>
            <IconButton
              color="inherit"
              aria-label="back"
              edge="start"
              onClick={() => {
                if (messages) navigate('/dashboard/messages');
              }}>
              <ArrowBack />
            </IconButton>
          </div>
        ) : (
          <div className={styles.actionButton}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleSidebar}>
              <Menu />
            </IconButton>
          </div>
        )
      ) : null}
      <div className={styles.logo}>
        <Link to={'/homepage'}>
          <div className={styles.detalks}>
            <img src={logo} alt="DeTalks" />
          </div>
        </Link>
      </div>
    </div>
  );
}
