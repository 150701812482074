import { Box, Button, Drawer, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  eventTypes
  // , timeSlots
} from '../../../../helper/data.helper';
import DateSelect from '../shared/DateSelect';
import LocationSelect from '../shared/LocationSelect';
import { useParams } from 'react-router-dom';
import { getTherapistAvailability } from '../../../../helper/api.helper';
import toast from 'react-hot-toast';
import TimeslotSelect from '../shared/TimeslotSelect';
import SessionSelect from '../shared/SessionSelect';

const MobileViewPopup = ({
  isDrawerOpen,
  toggleDrawer,
  selectedEventType,
  handleEventTypeChange,
  selectedLocation,
  handleLocationChange,
  therapist,
  selectedDate,
  handleDateClick,
  selectedTimeIndex,
  handleTimeClick,
  formatDate,
  services,
  locations,
  handleBook,
  selectedTime,
  orgData,
  setShowForm
}) => {
  const { id } = useParams();

  const [timeSlots, setTimeSlots] = useState([]);

  useEffect(() => {
    if (id && selectedDate && selectedEventType && selectedLocation) {
      getTherapistAvailability(id, {
        serviceId: selectedEventType,
        locationId: selectedLocation,
        selectedDate: selectedDate.getTime(),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status === 'success') {
            setTimeSlots(res.data.slots);
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => {
          toast.error(err.message);
        });
    }
  }, [id, selectedDate, selectedEventType, selectedLocation]);
  return (
    <>
      <Drawer
        anchor="bottom"
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: {
            top: '10%',
            // borderRadius: '15px',
            padding: '0px 10px 20px 10px'
          }
        }}>
        <Box>
          <SessionSelect
            selectedEventType={selectedEventType}
            handleEventTypeChange={handleEventTypeChange}
            services={services}
          />

          <LocationSelect
            selectedLocation={selectedLocation}
            handleLocationChange={handleLocationChange}
            therapist={therapist}
            locations={locations}
          />

          <DateSelect selectedDate={selectedDate} handleDateClick={handleDateClick} />
          <TimeslotSelect
            timeSlots={timeSlots}
            selectedTimeIndex={selectedTimeIndex}
            handleTimeClick={handleTimeClick}
          />
          <div
            className="flex-container justify-center margin-top--md"
            style={{ maxWidth: '350px' }}>
            {orgData?.qualified ? (
              <Button
                disabled={!selectedDate || !selectedEventType || !selectedLocation || !selectedTime}
                variant="contained"
                color="primary"
                size="small"
                fullWidth
                onClick={handleBook}
                sx={{ textTransform: 'none' }}>
                Book session for {formatDate(selectedDate)}
              </Button>
            ) : (
              <Button
                disabled={!selectedDate || !selectedEventType || !selectedLocation || !selectedTime}
                variant="contained"
                color="primary"
                size="small"
                fullWidth
                onClick={() => setShowForm(true)}
                sx={{ textTransform: 'none' }}>
                Proceed to checkout
              </Button>
            )}
          </div>
        </Box>
      </Drawer>
      <style jsx>
        {`
          p,
          h3,
          h4,
          .main-div > span,
          div {
            color: black;
          }
          li {
            text-align: center;
            padding: 5px 5px;
            cursor: pointer;
            border-radius: 3px;
            border: 1px solid #097eff;
            color: #097eff;
            margin-bottom: 10px;
            font-weight: 600;
            font-size: 12px;
            width: 120px;
          }
          li:hover {
            outline: white;
            color: white;
            background-color: #097eff;
          }
          .selected {
            background-color: #097eff;
            color: white;
          }
          .list-items {
            overflow: scroll;
            max-width: 350px;
            height: 300px;
            flex-wrap: wrap;
          }
          @media only screen and (max-width: 350px) {
            li {
              padding: 10px 10px;
            }
          }
        `}
      </style>
    </>
  );
};

export default MobileViewPopup;
