import { useState, Suspense } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Routes, Route, Navigate, NavLink } from 'react-router-dom';
import TopNav from '../../components/top-nav/TopNav';
import Sidebar from '../../components/sidebar/Sidebar';
import styles from './dashboard.module.scss';
import clsx from 'clsx';
import Loader from '../../components/loader/Loader';
import History from './history/History';
import Footer from '../../components/Footer';
import Profile from './profile/Profile';
import Messages from './messages/Messages';

const Dashboard = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#0884ff'
      },
      secondary: {
        main: '#73768c'
      }
    },
    typography: {
      button: {
        textTransform: 'capitalize'
      }
    }
  });
  const [sidebar, setSidebar] = useState(window.innerWidth > 960);

  const handleSidebar = () => {
    setSidebar((s) => !s);
  };

  return (
    <Suspense fallback={<Loader />}>
      <ThemeProvider theme={theme}>
        <div className={styles.wrapper}>
          <TopNav showUser handleSidebar={handleSidebar} />
          <div id="dashboard-container" className={styles.homeContent}>
            <Sidebar {...{ sidebar, handleSidebar }} />
            <div className={styles.homeContainer}>
              <Routes>
                <Route index element={<Navigate to="history" replace />} />

                <Route path="/history/*" element={<History />} />
                <Route path="/profile/*" element={<Profile />} />
                <Route path="/messages/*" element={<Messages />} />
              </Routes>
              {/* <Footer /> */}
            </div>
          </div>
        </div>
      </ThemeProvider>
    </Suspense>
  );
};

export default Dashboard;
