import React from 'react';
import { getTime } from '../../../messages.service';
import DoneAllIcon from '@mui/icons-material/DoneAll';

const MessageItem = ({ message, showDate, getDate, messageDate }) => {
  return (
    <>
      <div className="message-container">
        {showDate && <p className="message-date margin-bottom--sm margin-top--sm">{messageDate}</p>}
        <div
          className={`message ${message.status}`}
          style={{
            alignSelf: message.status === 'sent' ? 'flex-end' : 'flex-start',
            borderRight: message.status === 'sent' ? '5px solid #007aff' : 'none',
            borderLeft: message.status === 'received' ? '5px solid #007aff' : 'none'
          }}>
          <p>{message.text}</p>
          <p className="message-time">
            {getTime(message.time)}
            {message.status === 'sent' && (
              <DoneAllIcon
                color={message?.seen ? 'primary' : 'secondary'}
                style={{
                  marginBottom: '-3px',
                  fontSize: '14px',
                  marginLeft: '5px'
                }}
              />
            )}
          </p>
        </div>
      </div>
      <style jsx>
        {`
          .message-date {
            text-align: center;
            background-color: #e9f3ff;
            padding: 5px;
            color: var(--gray-light);
          }

          .message-container {
            display: flex;
            flex-direction: column;
            // margin: 10px;
          }

          .message {
            display: inline-block;
            max-width: 70%;
            margin: 5px 10px;
            padding: 5px 10px;
            color: black;
            background-color: #e9f3ff;
            word-wrap: break-word;
          }

          .message-time {
            font-size: 10px;
            color: var(--gray-lightest);
            text-align: end;
            margin-top: 5px;
          }
        `}
      </style>
    </>
  );
};

export default MessageItem;
