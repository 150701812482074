import { Button, InputAdornment, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import OTPInput from 'react-otp-input';
import Loader from '../../../components/loader/Loader';
import { useNavigate } from 'react-router-dom';
import { sendEmailVerificationOtp, verifyEmailOtp } from '../../../helper/api.helper';
import toast from 'react-hot-toast';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { getOrgCode } from '../../../helper/utils.helper';

const VerifyOrgEmail = () => {
  const [otp, setOtp] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [resendTimer, setResendTimer] = useState(60);

  const navigate = useNavigate();

  const handleSendOTP = () => {
    const orgCode = getOrgCode();
    setLoading(true);
    sendEmailVerificationOtp({ email: email, orgDomain: orgCode })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 'success') {
          setLoading(false);
          toast.success('OTP sent successfully!');
          setShowOTP(true);
          startResendTimer();
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error('OTP sending error : ' + error.message);
        console.log(error.message);
      });
  };

  const verifyOtp = () => {
    const orgCode = getOrgCode();
    setLoading(true);
    verifyEmailOtp({ otp: otp, email: email, orgDomain: orgCode })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 'success') {
          setLoading(false);
          toast.success('Email verified successfully');
          navigate('/homepage');
        } else {
          setLoading(false);
          toast.error('Verification failed: ', res.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.message);
        toast.error('Verification failed: ' + error.message);
      });
  };
  const startResendTimer = () => {
    setResendTimer(60);
    const timer = setInterval(() => {
      setResendTimer((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const resendOTP = () => {
    if (resendTimer === 0) {
      setOtp();
      handleSendOTP();
    } else {
      toast.error(`Please wait ${resendTimer} seconds before resending OTP.`);
    }
  };
  return (
    <div className="verify-email-wrapper">
      {loading ? <Loader /> : null}
      <div className="email-div">
        <h3 className="margin-top--xxs">Verify your organisation email address</h3>

        {showOTP ? (
          <>
            <p style={{ margin: '25px 0px' }}>Enter OTP below</p>
            <OTPInput
              value={otp}
              onChange={setOtp}
              numInputs={4}
              isInputNum
              shouldAutoFocus
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
              inputStyle={{
                width: '1.5rem',
                height: '1.5rem',
                margin: '0 0.5rem',
                fontSize: '1.2rem',
                borderRadius: '4px',
                border: '1px solid #0884ff'
              }}
            />
            <div className="flex-container justify-between">
              <Button
                size="small"
                sx={{ marginTop: '30px' }}
                onClick={resendOTP}
                disabled={resendTimer > 0}
                variant="outlined"
                color="primary">
                {resendTimer > 0 ? `Resend OTP in ${resendTimer}s` : 'Resend OTP'}
              </Button>
              <Button
                disabled={!otp}
                sx={{ marginTop: '30px' }}
                onClick={verifyOtp}
                variant="contained"
                size="small"
                color="primary">
                <span>Verify OTP</span>
              </Button>
            </div>
          </>
        ) : (
          <>
            {/* <p style={{ margin: '25px 0px' }}>Enter email address</p> */}
            <TextField
              required
              size="small"
              fullWidth
              sx={{ marginTop: '20px', maxWidth: '400px' }}
              type="email"
              id="email"
              onChange={(e) => setEmail(e.target.value)}
              label={<span className="text--sm">Email</span>}
              placeholder="Enter your email address"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailOutlinedIcon color="primary" />
                  </InputAdornment>
                )
              }}
            />
            <br />
            <Button
              sx={{ marginTop: '20px' }}
              onClick={handleSendOTP}
              variant="contained"
              disabled={!email}
              size="small"
              color="primary"
              id="send-otp">
              Send OTP
            </Button>
          </>
        )}
      </div>
      <Button
        sx={{
          marginTop: '20px',
          marginLeft: '2%',
          fontSize: 'var(--text-sm)'
        }}
        variant="outlined"
        onClick={() => {
          // toast.success("Skipping organisation email verification");
          navigate('/homepage');
        }}
        size="small"
        color="primary">
        Skip
      </Button>
      <style jsx>{`
        .verify-email-wrapper {
          max-width: 600px;
          margin: auto;
          margin-top: 100px;
          min-height: 80vh;
        }
        .email-div {
          margin: 0px 5px;
          padding: 20px;
          border: 1px solid #0884ff;
          border-radius: 10px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }
        @media only screen and (max-width: 350px) {
          .email-div {
            padding: 20px 10px 30px 10px;
          }
        }
      `}</style>
    </div>
  );
};

export default VerifyOrgEmail;
