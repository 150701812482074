import React from 'react';
import { Navigate } from 'react-router-dom';

const RouteGuard = ({ component: Component, redPath, isAuthenticated, ...rest }) => (
  <>
    {isAuthenticated ? (
      <Component {...rest} />
    ) : (
      <Navigate
        to={{
          pathname: redPath
        }}
      />
    )}
  </>
);

export default RouteGuard;
