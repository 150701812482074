import { useStoreState } from 'easy-peasy';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../../components/loader/Loader';
import { Button } from '@mui/material';
import AccordionSection from './accordion-section/AccordionSection';
import LegalForms from './legal-forms/LegalForms';
import { fetchFieldsFromDb } from '../history.service';

const ClientOrgProfile = () => {
  const [fields, setFields] = useState(null);
  const [groupedFields, setGroupedFields] = useState({});
  const [isLoading, setLoading] = useState(false);

  const navigate = useNavigate();

  const user = useStoreState((state) => state.user);
  // console.log(user);

  const groupFieldsBySection = (fields) => {
    return fields.reduce((acc, field) => {
      const section = field.section || 'Other';
      if (!acc[section]) {
        acc[section] = [];
      }
      acc[section].push(field);
      return acc;
    }, {});
  };

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      if (user?.orgId) {
        const fetchedFields = await fetchFieldsFromDb(user.orgId);
        setFields(fetchedFields);
        setGroupedFields(groupFieldsBySection(fetchedFields));
        setLoading(false);
      }
      setLoading(false);
    };

    fetchData();
  }, [user]);

  return (
    <div className="profile-wrapper">
      <Helmet>
        <title>Profile - DeTalks</title>
      </Helmet>
      {isLoading ? <Loader /> : null}
      <div className="details-div">
        <p>{user.displayName ? `Name - ${user.displayName}` : null}</p>
        <p className="margin-top--sm">{user.email ? `Email - ${user.email}` : null}</p>
        <p className="margin-top--sm">
          {user.phoneNumber ? (
            `Number - ${user.phoneNumber}`
          ) : (
            <Button
              size="small"
              variant="outlined"
              onClick={() => navigate('/register/register-number')}>
              Add Phone Number
            </Button>
          )}
        </p>
      </div>
      {Object.keys(groupedFields).map((section, index) => (
        <AccordionSection
          key={section}
          fields={fields}
          groupedFields={groupedFields}
          section={section}
          setLoading={setLoading}
        />
      ))}
      <LegalForms />

      <style jsx>{`
        .profile-wrapper {
          min-height: 90vh;
          padding: 20px;
        }
        .details-div {
          border-bottom: 1px solid var(--border-gray);
          padding-bottom: 20px;
        }
      `}</style>
    </div>
  );
};

export default ClientOrgProfile;
