import { Button, TextField } from '@mui/material';
import React from 'react';
import toast from 'react-hot-toast';

const FinalDetailsForm = ({
  formDetails,
  setFormDetails,
  handleBook,
  setShowForm,
  therapist,
  selectedDate,
  selectedTime
}) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    if (formDetails.email || formDetails.mobile) {
      handleBook();
    } else {
      toast.error('Please enter email address or mobile number.');
    }
  };
  return (
    <div
      className="flex-container align-center direction-column"
      style={{ height: '95vh', padding: '20px' }}>
      <h3 style={{ fontWeight: '600' }}>
        Book session with {therapist?.name} on{' '}
        {selectedDate ? selectedDate.toLocaleDateString('en-GB') : null} at {selectedTime.startTime}{' '}
        - {selectedTime.endTime}
      </h3>
      <div className="form-details-container margin-top--lg">
        <form onSubmit={handleSubmit}>
          <TextField
            // required
            onChange={(e) => setFormDetails({ ...formDetails, name: e.target.value })}
            fullWidth
            size="small"
            type="text"
            // helperText="Please fill this field"
            value={formDetails.name}
            label={'Name'}
            style={{ marginTop: '20px' }}
          />
          <TextField
            // required
            onChange={(e) => setFormDetails({ ...formDetails, email: e.target.value })}
            fullWidth
            size="small"
            type="email"
            // helperText="Please fill this field"
            value={formDetails.email}
            label={'Email'}
            style={{ marginTop: '20px' }}
          />
          <TextField
            // required
            onChange={(e) => setFormDetails({ ...formDetails, mobile: e.target.value })}
            fullWidth
            size="small"
            type="number"
            // helperText="Please fill this field"
            value={formDetails.mobile}
            label={'Mobile no.'}
            style={{ marginTop: '20px' }}
          />

          <div className="flex-container justify-between">
            <Button
              variant="outlined"
              size="small"
              style={{ marginTop: '20px' }}
              onClick={() => setShowForm(false)}>
              Back
            </Button>
            <Button type="submit" variant="contained" size="small" style={{ marginTop: '20px' }}>
              Confirm Booking
            </Button>
          </div>
        </form>
      </div>
      <style jsx>
        {`
          .form-details-container {
            max-width: 500px;
          }
        `}
      </style>
    </div>
  );
};

export default FinalDetailsForm;
