import React, { Suspense, useEffect } from 'react';
import { Navigate, Routes, Route, useLocation } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { useStoreState, useStoreActions } from 'easy-peasy';

import Homepage from './pages/homepage/Homepage';
import 'react-calendar/dist/Calendar.css';
import Login from './pages/login/Login';
import Register from './pages/register/Register';
import toast, { Toaster } from 'react-hot-toast';
import Loader from './components/loader/Loader';
import { auth, database } from './helper/firebase.helper';
import RouteGuard from './components/RouteGuard';
import { getIdTokenResult, onAuthStateChanged, signOut } from 'firebase/auth';
import Dashboard from './pages/dashboard/Dashboard';
import { getOrg } from './helper/api.helper';
import { getOrgCode } from './helper/utils.helper';

function App() {
  const setUser = useStoreActions((actions) => actions.setUser);
  const setLogo = useStoreActions((actions) => actions.setLogo);
  const setBanner = useStoreActions((actions) => actions.setBanner);
  const setQualified = useStoreActions((actions) => actions.setQualified);
  const setEmailDomains = useStoreActions((actions) => actions.setEmailDomains);
  const setClientData = useStoreActions((actions) => actions.setClientData);
  const { pathname } = useLocation();

  const user = useStoreState((state) => state.user);
  const isUserAuthenticated = user;

  useEffect(() => {
    const orgCode = getOrgCode();
    Promise.all([
      ...(user
        ? [
            getDoc(doc(database, `clients/${user.uid}`))
              .then((res) => {
                setClientData({
                  id: res.id,
                  ...res.data()
                });
              })
              .catch((err) => console.error(err))
          ]
        : []),
      getOrg(orgCode)
        .then((res) => res.json())
        .then((res) => {
          if (res.status === 'success') {
            setLogo(res.data.logo);
            setBanner(res.data.banner);
            setQualified(res.data.qualified);
            setEmailDomains(res.data.emailDomains);
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => {
          toast.error(err.message);
        })
    ]);
  }, [user]);

  useEffect(() => {
    onAuthStateChanged(auth, async (usr) => {
      if (usr) {
        getIdTokenResult(usr, true).then((res) => {
          if (res.claims?.userType === 0) {
            setUser({
              ...usr,
              orgId: res.claims.orgId,
              userType: res.claims.userType
            });
          } else {
            setUser(null);
          }
        });
      } else {
        setUser(usr);
      }
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Suspense fallback={<Loader />}>
      {user === false ? (
        <Loader />
      ) : (
        <Routes>
          <Route index element={<Navigate to="homepage" replace />} />
          <Route
            path="/login/*"
            element={
              <RouteGuard
                isAuthenticated={!isUserAuthenticated}
                redPath="/homepage"
                component={Login}
              />
            }
          />
          <Route path="/register/*" element={<Register />} />
          <Route path="/homepage/*" element={<Homepage />} />
          <Route
            path="/dashboard/*"
            element={
              <RouteGuard
                isAuthenticated={isUserAuthenticated}
                redPath="/login"
                component={Dashboard}
              />
            }
          />
        </Routes>
      )}
      <Toaster position="bottom-center" />
    </Suspense>
  );
}

export default App;
