import React, { useEffect, useState } from 'react';
import { getTime } from '../../messages.service';
import { peopleList } from '../../../../../helper/data.helper';
import Person from './person/Person';
import { useNavigate } from 'react-router';

const ChatList = () => {
  const [activeChat, setActiveChat] = useState(null);
  const [peoplesList, setPeoplesList] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    setPeoplesList(peopleList);
  }, []);

  const handleActiveChat = (person) => {
    const personId = person.id;
    setPeoplesList((prevPeople) =>
      prevPeople.map((person) => {
        if (person.id === personId) {
          const updatedMessages = person.messages.map((message) =>
            message.status === 'received' && !message.read ? { ...message, read: true } : message
          );
          return { ...person, messages: updatedMessages };
        }
        return person;
      })
    );
    setActiveChat(person.id);
    navigate(`/dashboard/messages/${person.id}`);
  };

  return (
    <div>
      {peoplesList?.map((person) => (
        <Person
          key={person.id}
          activeChat={activeChat}
          person={person}
          handleActiveChat={handleActiveChat}
        />
      ))}
    </div>
  );
};

export default ChatList;
