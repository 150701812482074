import React from 'react';
import Home from './home/Home';
import { Route, Routes } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import TherapistList from './therapist-listing/TherapistList';
import TherapistDetailPage from './therapist-detail/TherapistDetailPage';
import { useStoreState } from 'easy-peasy';
import RouteGuard from '../../components/RouteGuard';
import TopNav from '../../components/top-nav/TopNav';
import Footer from '../../components/Footer';
import Course from './home/courses/course/Course';

const Homepage = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#0884ff'
      },
      secondary: {
        main: '#73768c'
      }
    },
    typography: {
      button: {
        textTransform: 'capitalize'
      }
    }
  });
  const user = useStoreState((state) => state.user);
  const qualified = useStoreState((state) => state.qualified);
  const emailDomains = useStoreState((state) => state.emailDomains);
  const clientData = useStoreState((state) => state.emailDomains);
  const isEmailDomainVerified =
    user &&
    clientData &&
    emailDomains?.some((domain) =>
      [user.email, ...(clientData.verifiedEmails ?? [])].some((email) => email.endsWith(domain))
    );
  const isUserAuthenticated = !qualified || isEmailDomainVerified;

  return (
    <ThemeProvider theme={theme}>
      <TopNav showUser />
      <Routes>
        <Route path="/" element={<Home />} />

        <Route
          path="/therapist-list"
          element={
            <RouteGuard
              isAuthenticated={isUserAuthenticated}
              redPath={
                qualified && !isEmailDomainVerified
                  ? '/register/verify-organisation-email'
                  : '/login'
              }
              component={TherapistList}
            />
          }
        />
        <Route
          path="/therapist/:id"
          element={
            <RouteGuard
              isAuthenticated={isUserAuthenticated}
              redPath={
                qualified && !isEmailDomainVerified
                  ? '/register/verify-organisation-email'
                  : '/login'
              }
              component={TherapistDetailPage}
            />
          }
        />

        {/* <Route
          path="/course-list"
          element={
            <RouteGuard
              isAuthenticated={isUserAuthenticated}
              redPath="/login"
              component={}
            />
          }
        /> */}
        <Route
          path="/course/:id"
          element={
            <RouteGuard
              isAuthenticated={isUserAuthenticated}
              redPath={
                qualified && !isEmailDomainVerified
                  ? '/register/verify-organisation-email'
                  : '/login'
              }
              component={Course}
            />
          }
        />
      </Routes>
      <Footer />
    </ThemeProvider>
  );
};

export default Homepage;
