import React from 'react';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';

const LocationItem = ({ location }) => {
  return (
    <>
      <div className="map-img-div">
        <BusinessOutlinedIcon color="primary" />
      </div>
      <div className="phone-div">
        {location?.location}
        {/* Phone: {therapist.phone} */}
      </div>
      <style jsx>
        {`
          .map-img-div {
            // width: 200px;
          }
          .phone-div {
            width: 50%;
            margin-left: 10px;
          }
          @media only screen and (max-width: 431px) {
            .map-img-div {
              // width: 250px;
            }
            .phone-div {
              width: 80%;
              margin-left: 0px;
            }
          }
        `}
      </style>
    </>
  );
};

export default LocationItem;
