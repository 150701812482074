/* eslint-disable react/no-unescaped-entities */
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';

const PrivacyNotice = ({
  openPrivacyNoticeDialog,
  cancelPrivacyNoticeAccept,
  isPrivacyNoticeAccepted,
  confirmPrivacyNoticeAccept
}) => {
  return (
    <Dialog open={openPrivacyNoticeDialog} onClose={cancelPrivacyNoticeAccept}>
      <DialogTitle>
        <b> Notice of Privacy Practices</b>
      </DialogTitle>
      <DialogContent>
        <p>
          THIS NOTICE DESCRIBES HOW MEDICAL INFORMATION ABOUT YOU MAY BE USED AND DISCLOSED AND HOW
          YOU CAN GET ACCESS TO THIS INFORMATION. PLEASE REVIEW IT CAREFULLY
        </p>
        <b>Last Updated: August 8, 2023</b>
        <p className="margin-top--sm">
          <b>OVERVIEW</b>
        </p>
        <p>
          This notice will tell you about the ways Detalks (“Practice,” “we,” or “us”) may disclose
          health information about you and will also describe your rights and certain obligations
          that we have regarding the use and disclosure of your health information. Detalks is a
          behavioral health group that is operated across multiple legal entities which are referred
          to by the HIPAA Privacy Rule as an "organized health care arrangement." Detalks has
          relationships with the providers listed on this website and provides services via
          telehealth and at the service delivery sites of the providers listed on this website.
          Detalks's legal entities share protected health information with each other, as necessary
          to carry out Detalks's treatment, payment and health care operations. All of the legal
          entities that comprise Detalks agree to comply with the terms of this Notice of Privacy
          Practices. We are required by law to: make sure that health information that identifies
          you is kept private; give you this notice of our legal duties and privacy practices with
          respect to your health information; notify you following a breach of your unsecured
          protected health information; and follow the terms of the notice that are currently in
          effect. Although this notice is being provided to you electronically, and by signing an
          acknowledgment of receipt of this notice, you consent to the provision of this notice
          electronically, you have the right to request a paper copy of this notice. We reserve the
          right to change our privacy practices and the terms of this notice at any time. You may
          obtain a copy of the revised notice on this website. This notice is effective as of
          February 8, 2021.
        </p>
        <p className="margin-top--sm">
          <b>HOW YOUR INFORMATION IS USED</b>
        </p>
        <p>
          We may use and disclose your health information for the purposes of providing services and
          quality care. For the avoidance of doubt, providing treatment services, collecting payment
          and conducting healthcare operations are all necessary activities for quality care. State
          and federal laws allow us to use and disclose your health information for these purposes.
          Here are some helpful examples, but this list is not exhaustive: Using your information
          for providing treatment. For example, If your treating provider cannot prescribe
          medications but wants to refer you to a prescriber in your insurance network, he or she
          may use your health information for the purpose of referring you to a prescriber who is
          affiliated with the Practice. The Practice or its business associates may use and disclose
          health information in order to verify your insurance and coverage. Example of using and
          disclosing your health information for collecting payment The Practice or its business
          associates will submit claims for reimbursement to your insurance company in order for
          them to pay us for the services we provide to you, which requires using your health
          information. Examples of using and disclosing your health information for healthcare
          operations The Practice or its business associates will use and disclose your health
          information for the review of treatment procedures, and may use it to review documentation
          to ensure provider compliance. For uses and disclosures for purposes other than treatment,
          payment and operations, we are required to have your written authorization, unless the use
          or disclosure falls within an exception, such as those described below. Most uses and
          disclosures of psychotherapy notes (as that term is defined in the HIPAA Privacy Rule),
          uses and disclosures for marketing purposes, and disclosures that constitute the sale of
          Personal Information require your authorization. Authorizations can be revoked at any time
          to stop future uses/disclosures except to the extent that we may have already taken any
          action in reliance on your authorization.
        </p>
      </DialogContent>
      {!isPrivacyNoticeAccepted && (
        <DialogActions>
          <Button onClick={cancelPrivacyNoticeAccept}>Cancel</Button>
          <Button onClick={confirmPrivacyNoticeAccept} variant="contained">
            Accept and Continue
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default PrivacyNotice;
