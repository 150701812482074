import React, { useEffect, useState } from 'react';
import ChatList from './chat-list/ChatList';
import ChatBox from './chat-box/ChatBox';
import { useParams } from 'react-router';

const Inbox = () => {
  const { id } = useParams();

  return (
    <>
      <div className="flex-container inbox-container">
        {(window.innerWidth > 720 || (!id && window.innerWidth < 720)) && (
          <div className="chat-list-container">
            <ChatList />
          </div>
        )}
        {id ? (
          <div className="chat-box-container flex-container direction-column">
            <ChatBox id={id} />
          </div>
        ) : window.innerWidth > 720 ? (
          <div
            className="flex-container justify-center align-center chat-box-container"
            style={{ height: '83vh' }}>
            <p>we can display text here when no chat is opened.</p>
          </div>
        ) : null}
      </div>
      <style jsx>
        {`
          .inbox-container {
            height: 87vh;
            border: 1px solid var(--border-gray);
            // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
          }
          .chat-list-container {
            width: 30%;
            min-width: 280px;
            border-right: 2px solid var(--border-gray);
            overflow-y: auto;
            // padding: 0px 5px;
          }
          .chat-box-container {
            width: 70%;
            // padding: 5px;
            // overflow-y: auto;
          }
          @media only screen and (max-width: 720px) {
            .inbox-container {
              height: 90vh;
              border: none;
              box-shadow: none;
            }
            .chat-list-container {
              width: 100%;
            }
            .chat-box-container {
              width: 100%;
            }
          }
        `}
      </style>
    </>
  );
};

export default Inbox;
