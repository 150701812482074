import React from 'react';
import user from '../../../assets/user.png';

const ImageDetails = ({ therapist }) => {
  return (
    <>
      <div className="img-div">
        <img alt="profile" src={therapist.profilePicUrl || user} />
      </div>
      <div className="name-div">
        <h3 style={{ fontSize: '23px' }}> {therapist.name}</h3>
        <p style={{ fontSize: '17px' }}>{therapist.role}</p>
      </div>

      <style jsx>{`
        p,
        h3,
        div {
          color: black;
        }
        .img-div {
          width: 160px;
          min-width: 160px;
          height: 160px;
          min-height: 160px;
          margin: -50px 0px 0px 0px;
          // overflow: hidden;
          border-radius: 50%;
        }
        .img-div img {
          width: 160px;
          height: 160px;
          object-fit: cover;
          border-radius: 50%;
        }
        .name-div {
          padding: 0px 15px 0px 15px;
        }

        @media only screen and (max-width: 431px) {
          .img-div {
            width: 120px;
            height: 120px;
            min-width: 120px;
            min-height: 120px;
            margin: -50px 10px 0px 0px;
          }
          .img-div img {
            width: 100%;
            height: 100%;
          }
        }
      `}</style>
    </>
  );
};

export default ImageDetails;
