import { getIdToken } from 'firebase/auth';
import { auth } from './firebase.helper';

// eslint-disable-next-line no-undef
const BASE_URL = process.env.REACT_APP_BASE_URL;
// const BASE_URL = 'http://127.0.0.1:5001/detalks-test-23af7/asia-south1/api/';

export const makePost = async (url, data, headers = {}) => {
  const token = auth.currentUser ? await getIdToken(auth.currentUser) : '';
  return fetch(`${BASE_URL}${url}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
      ...headers
    }
  });
};

export const makeGet = async (url, headers = {}) => {
  const token = auth.currentUser ? await getIdToken(auth.currentUser) : '';
  return fetch(`${BASE_URL}${url}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
      ...headers
    }
  });
};

export const getOrg = (orgCode) => {
  const url = `organisation/${orgCode}`;
  return makeGet(url);
};

export const getOrgDetails = (orgCode) => {
  const url = `organisation/${orgCode}/details`;
  return makeGet(url);
};

export const initSelfAssessment = (assessment) => {
  const url = `organisation/assessment/${assessment}/init`;
  return makeGet(url);
};

export const initCourse = (course) => {
  const url = `organisation/course/${course}/init`;
  return makeGet(url);
};

export const getCourseActivity = (courseActivity) => {
  const url = `organisation/course/activity/${courseActivity}`;
  return makeGet(url);
};

export const initCourseAssessment = (courseActivity) => {
  const url = `organisation/course/${courseActivity}/assessment/init`;
  return makeGet(url);
};

export const quizSubmit = (courseActivity, answers) => {
  const url = `organisation/course/${courseActivity}/quiz`;
  return makePost(url, answers);
};

export const registerClient = (data) => {
  const url = `register/client`;
  return makePost(url, data);
};

export const getAllTherapists = (orgCode) => {
  const url = `organisation/${orgCode}/therapists`;
  return makeGet(url);
};

export const getTherapistDetails = (practitionerId) => {
  const url = `organisation/therapists/${practitionerId}`;
  return makeGet(url);
};

export const getTherapistAvailability = (practitionerId, data) => {
  const url = `organisation/therapists/${practitionerId}/availability`;
  return makePost(url, data);
};

export const confirmBooking = (orgCode, practitionerId, data) => {
  const url = `organisation/${orgCode}/therapists/${practitionerId}/meeting/book`;
  return makePost(url, data);
};

export const sendEmailVerificationOtp = (data) => {
  const url = `client/generate-otp`;
  return makePost(url, data);
};

export const verifyEmailOtp = (data) => {
  const url = `client/verify-otp`;
  return makePost(url, data);
};
