import React, { useState, useEffect } from 'react';
// import { therapists } from '../../../helper/data.helper';
import ListingPage from './listing-page/ListingPage';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { getAllTherapists } from '../../../helper/api.helper';
import { getOrgCode } from '../../../helper/utils.helper';
import Loader from '../../../components/loader/Loader';

const TherapistList = () => {
  const [allTherapists, setAllTherapists] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    const orgCode = getOrgCode();
    getAllTherapists(orgCode)
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 'success') {
          setAllTherapists(res.data);
        } else {
          toast.error(res.message);
          navigate('/homepage');
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.message);
      });
  }, []);

  return (
    <>
      <div className="outer-div flex-container">
        {isLoading ? <Loader /> : null}
        <div className="inner-div">
          {allTherapists?.map((therapist, index) => (
            <ListingPage therapist={therapist} key={therapist.id} />
          ))}
        </div>
        <style jsx>
          {`
            .outer-div {
              // margin-top: 80px;
              display: flex;
              justify-content: center;
              // align-items: center;
              min-height: 90vh;
            }
            .inner-div {
              width: 70%;
            }
            @media only screen and (max-width: 1050px) {
              .inner-div {
                width: 80%;
              }
            }

            @media only screen and (max-width: 950px) {
              .inner-div {
                width: 95%;
              }
            }
          `}
        </style>
      </div>
    </>
  );
};

export default TherapistList;
