import React from 'react';
import { Button } from '@mui/material';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
// import QuizIcon from "@mui/icons-material/Quiz";
import AssignmentIcon from '@mui/icons-material/Assignment';
import ListAltIcon from '@mui/icons-material/ListAlt';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import QuizIcon from '@mui/icons-material/Quiz';

const componentList = ['assessment', 'pdf', 'quiz', 'assignment'];

function CourseNavigation({
  id,
  activeComponent,
  setActiveComponent,
  visitedPages,
  setVisitedPages
}) {
  const handleNavigation = (component) => {
    setActiveComponent(component);
  };

  const handlePrevious = () => {
    const currentIndex = componentList.indexOf(activeComponent);
    if (currentIndex > 0) {
      const previousComponent = componentList[currentIndex - 1];
      handleNavigation(previousComponent);
    }
  };

  const handleNext = () => {
    const currentIndex = componentList.indexOf(activeComponent);
    if (currentIndex < componentList.length - 1) {
      const nextComponent = componentList[currentIndex + 1];
      handleNavigation(nextComponent);
    }
  };

  return (
    <div className="flex-container navbar">
      <Button
        disabled={activeComponent === componentList[0]}
        onClick={handlePrevious}
        startIcon={<ChevronLeft />}
        sx={{ padding: '0px 30px' }}>
        {window.innerWidth > 600 ? 'Previous' : ''}
      </Button>
      {componentList.map((component, index) => {
        let Icon;
        switch (component) {
          case 'assessment':
            Icon = ListAltIcon;
            break;

          case 'pdf':
            Icon = SlideshowIcon;
            break;
          case 'quiz':
            Icon = QuizIcon;
            break;
          case 'assignment':
            Icon = AssignmentIcon;
            break;
          default:
            Icon = null;
        }
        return (
          <div
            key={component}
            style={{
              borderRight: index < componentList.length ? '1px solid lightgrey' : 'none',
              borderLeft: index === 0 ? '1px solid lightgrey' : 'none'
            }}
            onClick={() => handleNavigation(component)}
            className={`nav-button ${activeComponent === component ? 'active' : ''}`}>
            {Icon && (
              <Icon
                style={{
                  color: visitedPages[component] ? 'green' : 'secondary'
                }}
              />
            )}
          </div>
        );
      })}
      <Button
        disabled={activeComponent === componentList[componentList.length - 1]}
        endIcon={<ChevronRight />}
        onClick={handleNext}
        sx={{ padding: '0px 30px' }}>
        {window.innerWidth > 600 ? 'Next' : ''}
      </Button>

      <style jsx>
        {`
          .navbar {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            border-bottom: 1px solid lightgrey;
          }
          .nav-button {
            border-right: '1px solid lightgrey';
            padding: 10px;
            cursor: pointer;
            font-size: 16px;
            flex-grow: 1;
            text-align: center;
          }
          .nav-button.active {
            border-bottom: 2px solid #0884ff;
          }
        `}
      </style>
    </div>
  );
}

export default CourseNavigation;
