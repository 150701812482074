/* eslint-disable no-irregular-whitespace */
import React from 'react';
import Calendar from 'react-calendar';

const DateSelect = ({ selectedDate, handleDateClick }) => {
  return (
    <>
      <h4 className="margin-bottom--sm">Select your preferred meeting time.</h4>
      <Calendar
        value={selectedDate}
        onClickDay={handleDateClick}
        minDate={new Date()}
        calendarType="gregory"
        // tileDisabled={tileDisabled}
      />
      <style jsx>
        {`
          h4 {
            color: black;
          }
        `}
      </style>
    </>
  );
};

export default DateSelect;
