import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import {
  fetchClientDetailsFromDb,
  fetchFieldsFromDb,
  getDate,
  getStatusColor,
  getTime
} from './history.service';
import { Link, useNavigate } from 'react-router-dom';
import { initCourse } from '../../../helper/api.helper';
import toast from 'react-hot-toast';
import { useStoreState } from 'easy-peasy';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const HistoryItem = ({ historyItem }) => {
  console.log(historyItem);
  const navigate = useNavigate();
  const user = useStoreState((state) => state.user);
  const clientId = user.uid;
  const orgId = user.orgId;
  const [missingMandatory, setMissingMandatory] = useState(false);

  useEffect(() => {
    const checkMandatoryFields = async () => {
      try {
        const clientDetails = await fetchClientDetailsFromDb(clientId);
        const fields = await fetchFieldsFromDb(orgId);

        const missingField = fields.some((field) => field.mandatory && !clientDetails[field.id]);

        setMissingMandatory(missingField);
      } catch (error) {
        console.error('Error checking mandatory fields:', error);
      }
    };

    checkMandatoryFields();
  }, [clientId, orgId]);

  const handleClick = () => {
    if (historyItem.type === 0 && historyItem.status === 1) {
      const url = historyItem.report_url;
      window.open(url);
    } else if (historyItem.type === 0 && historyItem.status === 0) {
      const url = historyItem.assessment_url;
      window.open(url);
    } else if (historyItem.type === 1 && historyItem.status === 1) {
      const url = historyItem.certificate_url;
      window.open(url);
    } else if (historyItem.type === 1 && historyItem.status === 0) {
      navigate(`/homepage/course/${historyItem.id}`);
    }
  };

  return (
    <div className="flex-container text--sm  outer-container margin-top--md ">
      <div className="icon-div">
        <div
          className="icon flex-container justify-center align-center"
          style={{ backgroundColor: getStatusColor(historyItem.status) }}>
          {historyItem.client_email.charAt(0).toUpperCase()}
        </div>
      </div>
      <div className="activity-div inner-div">
        <p className="semi-bold">
          {historyItem.type === 0
            ? 'Self-Assessment'
            : historyItem.type === 1
              ? 'Short Course'
              : historyItem.type === 2
                ? 'Booking'
                : null}
        </p>
        {historyItem.type === 2 && (
          <>
            <p>
              {getDate(historyItem.meetingStart)} at {getTime(historyItem.meetingStart)} -{' '}
              {getTime(historyItem.meetingEnd)}
            </p>
            <p className="semi-bold">{historyItem.serviceName}</p>
          </>
        )}

        <p className="semi-bold">{historyItem.name}</p>

        <p className="semi-bold">{historyItem.activityName}</p>
      </div>
      <div className="status-div inner-div">
        {historyItem.type === 2 ? (
          <span className="semi-bold" style={{ color: getStatusColor(historyItem.status) }}>
            {historyItem.status === 0
              ? 'Scheduled'
              : historyItem.status === 1
                ? 'Completed'
                : historyItem.status === 2
                  ? 'Expired'
                  : historyItem.status === 3
                    ? 'Cancelled'
                    : null}
          </span>
        ) : (
          <span className="semi-bold" style={{ color: getStatusColor(historyItem.status) }}>
            {historyItem.status === 0
              ? 'Initiated'
              : historyItem.status === 1
                ? 'Completed'
                : historyItem.status === 2
                  ? 'Expired'
                  : null}
          </span>
        )}
        &nbsp;&nbsp;
        <span className="semi-bold">{getDate(historyItem.update_time)}</span>
        {historyItem.type === 2 && (
          <>
            {/* <p className="semi-bold"> {getDate(historyItem.meetingStart)} </p> */}

            <p className="semi-bold"> {historyItem?.locationName} </p>
            {/* <p className="semi-bold"> Paid Online </p> */}
          </>
        )}
        <div className="semi-bold">
          {historyItem.key_metrics && historyItem.score ? (
            <div>
              Score : {historyItem.score}
              {historyItem.key_metrics.map((item, index) => {
                return (
                  <p key={index}>
                    {item.key}: {item.value}
                  </p>
                );
              })}
            </div>
          ) : historyItem.key_metrics ? (
            historyItem.key_metrics.map((item, index) => {
              return item.key ? (
                <p key={index}>
                  {item.key}: {item.value}
                </p>
              ) : null;
            })
          ) : null}
          {!historyItem.key_metrics && (historyItem.score || historyItem.score === 0)
            ? `Score : ${historyItem.score}`
            : null}
        </div>
      </div>

      <div className="actions-div inner-div">
        <div className={historyItem.type !== 2 && historyItem.status === 2 ? 'btn' : null}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClick}
            fullWidth
            size="small"
            style={{
              display: `${historyItem.type === 2 ? 'none' : 'flex'}`,
              maxHeight: '50px'
            }}>
            {historyItem.type === 1 && historyItem.status === 1
              ? 'View certificate'
              : historyItem.type === 0 && historyItem.status === 1
                ? 'View Report'
                : historyItem.type === 0 && historyItem.status === 0
                  ? 'Start assessment'
                  : historyItem.type === 1 && historyItem.status === 1
                    ? 'View Certificate'
                    : historyItem.type === 1 && historyItem.status === 0
                      ? 'Continue course'
                      : null}
          </Button>
          {historyItem.assignment_url && (
            <Button
              size="small"
              target="_blank"
              fullWidth
              style={{
                justifyContent: 'flex-start',
                textAlign: 'left'
              }}
              href={historyItem.assignment_url}>
              View assignment
            </Button>
          )}
          {historyItem.report_url && historyItem.type !== 0 ? (
            <Button
              fullWidth
              size="small"
              style={{
                justifyContent: 'flex-start',
                textAlign: 'left'
              }}
              target="_blank"
              href={historyItem.report_url}>
              View report
            </Button>
          ) : null}
        </div>
        {historyItem.type === 2 && historyItem.meetingLink && (
          <Button
            target="_blank"
            href={historyItem.meetingLink}
            variant="contained"
            color="primary"
            size="small"
            fullWidth>
            Join meeting
          </Button>
        )}
        <Button
          fullWidth
          size="small"
          style={{
            justifyContent: 'flex-start',
            textAlign: 'left'
          }}
          endIcon={missingMandatory ? <WarningAmberIcon style={{ color: '#ff0000' }} /> : null}>
          <Link to={`${user.orgId}`}>Intake Tasks</Link>
        </Button>
      </div>
      <style jsx>
        {`
          .outer-container {
            border-bottom: 1px solid var(--border-gray);
            padding-bottom: 10px;
            justify-content: space-between;
          }
          .icon-div {
            width: 4%;
          }
          .activity-div {
            width: 25%;
            overflow: hidden;
          }

          .status-div {
            width: 35%;
          }
          .actions-div {
            width: 25%;
          }
          .inner-div {
            color: var(--gray-darkest);
          }
          .icon {
            background-color: var(--primary-color);
            color: white;
            fontSize: '24px'
            padding: 10px;
            width:30px;
            height:30px;
            border-radius: 50%;
          }
          .btn{
            visibility:hidden;
          }
          .semi-bold {
            font-weight: 500;
          }

          @media only screen and ( max-width : 900px ) {
            .activity-div {
              width: 40% !important;
            }
            .source-div {
              display: none ;
            }
            .status-div {
              display: none;
            }
            .actions-div {
              width: 42% !important;
            }
          }

          @media only screen and (max-width: 350px) {
            .icon-div {
              display: none;
            }
            .actions-div {
              width: 54% !important;
            }
          }
        `}
      </style>
    </div>
  );
};

export default HistoryItem;
