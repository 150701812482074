import React, { useState, memo, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import { toast } from 'react-hot-toast';

import { signInWithEmailAndPassword } from 'firebase/auth';

import style from './loginmail.module.scss';
import { auth } from '../../../helper/firebase.helper';

// eslint-disable-next-line react/display-name
const LoginMail = memo(({ validateUser }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();

  const handleEmail = (event) => {
    setEmail(event.target.value.trim());
    if (!event.target.value) {
      setErrors({
        ...errors,
        emailError: true
      });
    } else {
      setErrors({
        ...errors,
        emailError: false
      });
    }
  };

  const handlePassword = (event) => {
    setPassword(event.target.value);
    if (!event.target.value) {
      setErrors({
        ...errors,
        passwordError: true
      });
    } else {
      setErrors({
        ...errors,
        passwordError: false
      });
    }
  };

  const signIn = () => {
    if (email && password) {
      signInWithEmailAndPassword(auth, email, password)
        .then((user) => {
          toast('Logging in...');
          validateUser(user);
        })
        .catch((err) => {
          console.log(err.code);
          if (err.code === 'auth/invalid-credential') {
            toast.error('Invalid credentials');
          } else if (err.code === 'auth/invalid-email') {
            toast.error('Invalid email');
          } else {
            toast.error(err.message);
          }
        });
    } else if (!email) {
      setErrors({
        ...errors,
        emailError: true
      });
    } else if (!password) {
      setErrors({
        ...errors,
        passwordError: true
      });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      signIn();
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem('loginEmail')) {
      setEmail(sessionStorage.getItem('loginEmail'));
      sessionStorage.removeItem('loginEmail');
    }
  }, []);

  return (
    <form className={style.form} autoComplete="off">
      <div className={style.field}>
        <TextField
          required
          label="Email"
          variant="outlined"
          id="email"
          value={email}
          onKeyDown={handleKeyDown}
          onChange={(e) => handleEmail(e)}
          style={{ width: '100%' }}
          error={errors.emailError}
          helperText={errors.emailError ? 'please fill the email in correct format' : ''}
        />
      </div>
      <div className={style.field}>
        <TextField
          required
          type="password"
          label="Password"
          variant="outlined"
          value={password}
          error={errors.passwordError}
          onKeyDown={handleKeyDown}
          style={{ width: '100%' }}
          autoComplete="current-password"
          onChange={(e) => handlePassword(e)}
          helperText={errors.passwordError ? 'Please enter password' : ''}
        />
      </div>
      <Link to="forgot-password" className={style.forgot}>
        Forgot password
      </Link>
      <div className={style.field}>
        <Button
          // href="/dashboard"
          style={{ width: '100%' }}
          variant="contained"
          size="large"
          color="primary"
          onClick={signIn}>
          Sign in
        </Button>
      </div>
    </form>
  );
});

export default LoginMail;
